import {
    createUpdateSubscription,
    deleteSubscription,
    getMySubscriptions,
    PaymentGateway,
    SimpleProductResponse,
    SimpleProductSubscriptionResponse,
    SubscribeRequestStatus,
} from '../../api/productsClient';
import { apiRequestFailed } from './actions';
import getStripe from '../../api/stripeClient';
import { PageContext } from '../../api/utils';

export const FETCH_MY_SUBSCRIPTIONS_REQUESTED =
    'FETCH_MY_SUBSCRIPTIONS_REQUESTED';
export const FETCH_MY_SUBSCRIPTIONS_COMPLETED =
    'FETCH_MY_SUBSCRIPTIONS_COMPLETED';

export const fetchMySubscriptionsRequested = () => ({
    type: FETCH_MY_SUBSCRIPTIONS_REQUESTED,
});
export const fetchMySubscriptionsCompleted = (
    mySubscriptions: SimpleProductSubscriptionResponse[],
) => ({
    type: FETCH_MY_SUBSCRIPTIONS_COMPLETED,
    mySubscriptions,
});

export const fetchMySubscriptions = (ctx: PageContext | null) => {
    return async (dispatch) => {
        dispatch(fetchMySubscriptionsRequested());
        try {
            const mySubscriptions = await getMySubscriptions(ctx);
            return dispatch(fetchMySubscriptionsCompleted(mySubscriptions));
        } catch (e) {
            dispatch(apiRequestFailed('Failed to search scripts', e));
        }
    };
};

export const SUBSCRIBE_TO_SCRIPT_REQUESTED = 'SUBSCRIBE_TO_SCRIPT_REQUESTED';
export const SUBSCRIBE_TO_SCRIPT_COMPLETED = 'SUBSCRIBE_TO_SCRIPT_COMPLETED';

export const subscribeToScriptRequested = () => ({
    type: SUBSCRIBE_TO_SCRIPT_REQUESTED,
});
export const subscribeToScriptCompleted = () => ({
    type: SUBSCRIBE_TO_SCRIPT_COMPLETED,
});

export const subscribeToScript = (
    script: SimpleProductResponse,
    paymentGateway: PaymentGateway = PaymentGateway.Stripe,
    country: string = null,
) => {
    return async (dispatch) => {
        dispatch(subscribeToScriptRequested());
        try {
            const result = await createUpdateSubscription(
                null,
                script.id,
                paymentGateway,
                country,
            );
            dispatch(fetchMySubscriptions(null));
            if (result.status !== SubscribeRequestStatus.Failed) {
                if (result.stripeSessionId) {
                    const stripe = await getStripe();
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: result.stripeSessionId,
                    });
                    if (error) {
                        return dispatch(
                            apiRequestFailed(
                                'Failed to create stripe session',
                                error,
                            ),
                        );
                    }
                }
                if (result.redirectTo && window) {
                    window.location.href = result.redirectTo;
                }

                return dispatch(subscribeToScriptCompleted());
            }
            return dispatch(
                apiRequestFailed('Failed to subscribe to script', null),
            );
        } catch (e) {
            dispatch(apiRequestFailed('Failed to subscribe to script', e));
        }
    };
};

export const UNSUBSCRIBE_FROM_SCRIPT_REQUESTED =
    'UNSUBSCRIBE_FROM_SCRIPT_REQUESTED';
export const UNSUBSCRIBE_FROM_SCRIPT_COMPLETED =
    'UNSUBSCRIBE_FROM_SCRIPT_COMPLETED';

export const unsubscribeFromScriptRequested = () => ({
    type: UNSUBSCRIBE_FROM_SCRIPT_REQUESTED,
});
export const unsubscribeFromScriptCompleted = () => ({
    type: UNSUBSCRIBE_FROM_SCRIPT_COMPLETED,
});

export const unsubscribeFromScript = (script: SimpleProductResponse) => {
    return async (dispatch) => {
        dispatch(unsubscribeFromScriptRequested());
        try {
            const result = await deleteSubscription(null, script.id);
            dispatch(fetchMySubscriptions(null));
            if (result.status !== SubscribeRequestStatus.Failed) {
                return dispatch(unsubscribeFromScriptCompleted());
            }

            return dispatch(
                apiRequestFailed('Failed to unsubscribe from script', null),
            );
        } catch (e) {
            dispatch(apiRequestFailed('Failed to unsubscribe from script', e));
        }
    };
};
